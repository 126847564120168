import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge, unset } from 'lodash'

interface OutletFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  filter: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: OutletFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  filter: {},
  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}


const initState = cloneDeep($state)


export const $actions: ActionTree<OutletFormState, RootState> = {
  loadData({ commit, state }, params) {
    const vm = (this as any)._vm
    commit('START_LOADING')
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/notifications`, { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
}

export const $mutations: MutationTree<OutletFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_ITEMS_LIST(state, data) {
    console.log(data)
    state.documents = data.notifications

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },

}

const $getters = {}

export const STORE_KEY = '$_notifications'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
